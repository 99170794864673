import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../Header'
import Footer from '../Footer'

import '../../styles/index.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: false,
    }
  }

  noScroll = () => {
    document.documentElement.classList.add('no-scroll')
    document.body.classList.add('no-scroll')
  }

  resumeScroll = () => {
    document.documentElement.classList.remove('no-scroll')
    document.body.classList.remove('no-scroll')
  }

  componentDidMount = () => {
    const cursor = document.querySelectorAll('.cursor')[0]

    const positionCursor = event => {
      cursor.style.top = event.y + 'px'
      cursor.style.left = event.x + 'px'
    }

    let firstMouseMove = false
    window.onmousemove = event => {
      if (!firstMouseMove) {
        cursor.style.opacity = 1
        firstMouseMove = !firstMouseMove
      }
      return positionCursor(event)
    }

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      if (window.innerWidth >= 990) {
        document.getElementsByClassName("logo")[0].style.display = "block";
        this.resumeScroll()
      }
    })
  }

  toggleNav = () => {
    

    this.setState({
      navOpen: !this.state.navOpen,
    })

    if (this.state.navOpen) {
      document.getElementsByClassName("logo")[0].style.display = "block";
      this.resumeScroll()
    } else {
      //hide the logo if the menu is showing. Really gross but beats redesigning the whole site
      document.getElementsByClassName("logo")[0].style.display = "none";
      this.noScroll()
    }
  }

  render() {
    const { children, bg } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            prismicSettings {
              data {
                facebook_url
                instagram_handle
                linkedin_handle
              }
            }
          }
        `}
        render={data => {
          const page = data.site.siteMetadata
          const settings = data.prismicSettings.data
          return (
            <>
              <Helmet title={`${page.title}`} />
              <div
                className={`font-sans type-base text-charcoal antialiased min-h-screen ${
                  bg ? bg : 'bg-grey'
                  }`}
              >
                <Header
                  bg={bg}
                  navOpen={this.state.navOpen}
                  toggleNav={this.toggleNav}
                />
                <div>{children}</div>
                <Footer settings={settings} bg={bg} />
                <div className="cursor" />
              </div>
            </>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
