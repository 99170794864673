import React from 'react'

import FooterMenu from '../FooterMenu'
import Logo from '../Logo'
import IconFacebook from '../SVG/IconFacebook'
import IconInstagram from '../SVG/IconInstagram'
import IconLinkedIn from '../SVG/IconLinkedIn'

const Footer = ({ bg, settings }) => (
  <footer className="pt-5 pb-12">
    <div className="menu-footer-grid">
      <div className="menu-footer-grid__half--primary mb-8 lg:mb-0">
        <div className="md:flex justify-between lg:justify-start items-center">
          <div className="w-30">
            <Logo />
          </div>
          <div className="lg:ml-8 mt-8 md:mt-0 text-xxs text-grey-lightest flex">
            <div className="w-6 h-6 mr-4 md:mr-0 md:ml-5">
              <a
                href={settings.facebook_url}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-yellow text-white transition-ease-color"
              >
                <IconFacebook />
              </a>
            </div>
            <div className="w-6 h-6 mr-4 md:mr-0 md:ml-5">
              <a
                href={`https://www.instagram.com/${settings.instagram_handle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-yellow text-white transition-ease-color"
              >
                <IconInstagram />
              </a>
            </div>
            <div className="w-6 h-6 mr-4 md:mr-0 md:ml-5">
              <a
                href={`https://www.linkedin.com/company/${settings.linkedin_handle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-yellow text-white transition-ease-color"
              >
                <IconLinkedIn />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="block menu-footer-grid__half--secondary self-center">
        <div className="md:flex justify-between items-center">
          <div className="mb-6 md:mb-0">
            <FooterMenu
              bg={bg}
              listClass="flex flex-wrap"
              itemClass="w-1/2 md:w-auto text-xxs"
            />
          </div>
          <small className="lg:ml-8 text-xxs text-grey-lightest">
            &copy; Puro {new Date().getFullYear()}
          </small>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
