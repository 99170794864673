import React from 'react'

import { Link } from 'gatsby'

import MainMenu from '../MainMenu'
import Logo from '../Logo'

class Header extends React.Component {
  render() {
    const { bg, navOpen, toggleNav } = this.props

    return (
      <div>
        <nav className="absolute pin-t pin-l pt-5 z-40 w-full">
          <div className="menu-grid">
            <div className="menu-grid__half--primary">
              <div className="w-48 lg:w-56">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
            </div>
            <div
              className={`lg:hidden hamburger ${bg ? 'hamburger--white' : ''} ${
                navOpen ? 'hamburger--nav-is-open' : ''
              }`}
              onClick={() => {
                toggleNav()
              }}
            />
            <div className="hidden lg:block menu-grid__half--secondary self-center">
              <MainMenu
                bg={bg}
                listClass="-mr-4"
                itemClass="w-1/2 font-medium"
              />
            </div>
          </div>
        </nav>
        <div
          className={`lg:hidden absolute inset-0 w-full h-full bg-charcoal z-20 overflow-auto ${
            navOpen ? 'block' : 'hidden'
          }`}
        >
          <div className="h-full flex justify-center mt-20">
            <MainMenu
              toggleNav={toggleNav}
              bg="bg-charcoal"
              itemClass="block text-xl font-medium menu-link--white text-center"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Header
