import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useRef, useState } from 'react'

const MenuItem = ({ title, link, itemclass, bg, toggleNav, hideOnDesktopHeader, children }) => 
{

  const [childOpen, setChildOpen] = useState(false);

  const toggleChildOpen = () => {setChildOpen(!childOpen)}

  const menuHandler = (event) => {
      
    event.preventDefault();
    event.stopPropagation()
      
    toggleChildOpen();

  }

  return(
  <div className="dropdown">
    <Link
      onClick={toggleNav}
      to={link}
      activeClassName={`${
        bg === 'bg-charcoal' ? 'menu-link--white-active' : 'menu-link--active'
      }`}
      className={`menu-link ${itemclass && itemclass} ${
        bg === 'bg-charcoal' ? 'menu-link--white' : ''
      } ${hideOnDesktopHeader === true ? 'lg:hidden' : ''}`}
    > 
    
      {title}
      {children.length > 0 &&
        <div onClick={(event) => { menuHandler(event); }} className="text-yellow expand"> {childOpen ? '–' : '+'}</div>
      }
      
    </Link>
    
    {children.length > 0 &&

    
    <div style={childOpen ? {display: `block`} : {display: `none`}}>
      {children.map( (item, key) => {

        return item.child_scroll_to ? 
         <div key={key}><Link onClick={toggleNav} to={ `${ item.child_link.url }#${ item.child_scroll_to }` }>{ item.child_title.text }</Link></div>
         
         :
         
         <div key={key}><Link onClick={toggleNav} to={ item.child_link.url }>{ item.child_title.text }</Link></div>

      })}
    </div>
    }
    
  </div>)

}


const MainMenu = ({ toggleNav, menuList, listClass, itemClass, bg }) => 
{ 
  const menuOpen = useRef(false);

  return(
    <StaticQuery
      query={graphql`
        query {
          allPrismicMenuLink(sort: { fields: [data___order], order: ASC }) {
            totalCount
            edges {
              node {
                data {
                  title
                  hide_on_desktop_header
                  link {
                    isBroken
                    document {
                      ... on PrismicAbout {
                        uid
                      }
                      ... on PrismicPlans {
                        uid
                      }
                      ... on PrismicFaqs {
                        uid
                      }
                      ... on PrismicJoinUs {
                        uid
                      }
                      ... on PrismicNewsIndex {
                        uid
                      }
                      ... on PrismicContact {
                        uid
                      }
                      ... on PrismicImageGallery {
                        uid
                      }
                      ... on PrismicHome {
                        uid
                      }
                      


                    }
                  }
                  child_menu_items{
                    child_title{
                      text
                    }
                    child_order
                    child_link {
                      url
                    }
                    child_scroll_to
                  }
                }
              }
            }
          }
        }
      `}


      render={data => {
        const { allPrismicMenuLink } = data

        if (allPrismicMenuLink.totalCount && allPrismicMenuLink.totalCount > 0) {
          menuList = menuList.concat(allPrismicMenuLink.edges)
        }
        return (
          <div className={listClass}>
            {menuList.map(({ node }) => {
              // If no relationship filed or Relationship link is broken, return null
              if (!node.data.link || node.data.link.isBroken) return null
              // Slug is page's slug, relationship filed
              const slug = node.data.link.document[0].uid == 'home' ? '/' : node.data.link.document[0].uid
              return (
                <MenuItem
                  toggleNav={toggleNav}
                  title={node.data.title}
                  link={`/${slug}`}
                  key={node.data.title}
                  itemclass={itemClass}
                  bg={bg}
                  hideOnDesktopHeader={node.data.hide_on_desktop_header}
                  children={node.data.child_menu_items}
                  menuOpen={menuOpen}
                />
              )
            })}
          </div>
        )
      }}
    />
  )
}
MainMenu.defaultProps = {
  menuList: [
    /*{
      node: {
        id: 'home-index',
        data: {
          title: 'Home',
          link: {
            isBroken: false,
            document: [{ uid: '' }],
          },
        },
      },
    },*/
  ],
}

MainMenu.propTypes = {
  menuList: PropTypes.array.isRequired,
}

export default MainMenu
