import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'

const FooterItem = ({ title, link, itemclass, bg, toggleNav }) => (
  <Link
    onClick={toggleNav}
    to={link}
    activeClassName={`${
      bg === 'bg-charcoal' ? 'menu-link--white-active' : 'menu-link--active'
    }`}
    className={`menu-link ${itemclass && itemclass} ${
      bg === 'bg-charcoal' ? 'menu-link--white' : ''
    }`}
  >
    {title}
  </Link>
)

const FooterMenu = ({ toggleNav, menuList, listClass, itemClass, bg }) => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicMenuLink(sort: { fields: [data___order], order: ASC }) {
          totalCount
          edges {
            node {
              data {
                title
                link {
                  isBroken
                  document {
                    ... on PrismicAbout {
                      uid
                    }
                    ... on PrismicPlans {
                      uid
                    }
                    ... on PrismicFaqs {
                      uid
                    }
                    ... on PrismicJoinUs {
                      uid
                    }
                    ... on PrismicNewsIndex {
                      uid
                    }
                    ... on PrismicContact {
                      uid
                    }
                    ... on PrismicImageGallery {
                      uid
                    }
                    ... on PrismicHome {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { allPrismicMenuLink } = data

      if (allPrismicMenuLink.totalCount && allPrismicMenuLink.totalCount > 0) {
        menuList = allPrismicMenuLink.edges
      }
      return (
        <div className={listClass}>
          {menuList.map(({ node }) => {
            // If no relationship filed or Relationship link is broken, return null
            if (!node.data.link || node.data.link.isBroken) return null
            // Slug is page's slug, relationship filed
            const slug = node.data.link.document[0].uid == 'home' ? '/' : node.data.link.document[0].uid
            return (
              <FooterItem
                toggleNav={toggleNav}
                title={node.data.title}
                link={`/${slug}`}
                key={node.data.title}
                itemclass={itemClass}
                bg={bg}
              />
            )
          })}
        </div>
      )
    }}
  />
)

FooterMenu.propTypes = {
  menuList: PropTypes.array.isRequired,
}

export default FooterMenu
